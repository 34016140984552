import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/weekly/weekly/packages/website/src/components/Layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  fragment ToolInfo on WESTACK_Tool {
    about
    id
    key
    name
    source
    website
    link
    iconURL(size: 200)
  }
  {
    Paddlehub: westack {
      tool(id: "paddlehub") {
        ...ToolInfo
      }
    }
    Bubbletea: westack {
      tool(id: "bubble-tea") {
        ...ToolInfo
      }
    }
    WXInlinePlayer: westack {
      tool(id: "wxinlineplayer") {
        ...ToolInfo
      }
    }
    Hyperfine: westack {
      tool(id: "hyperfine") {
        ...ToolInfo
      }
    }
    NoSleep: westack {
      tool(id: "nosleep") {
        ...ToolInfo
      }
    }
    Turndown: westack {
      tool(id: "turndown") {
        ...ToolInfo
      }
    }
    TransferSH: westack {
      tool(id: "transfer-sh") {
        ...ToolInfo
      }
    }
    QuestDB: westack {
      tool(id: "questdb") {
        ...ToolInfo
      }
    }
    Pattern: westack {
      tool(id: "pattern-css") {
        ...ToolInfo
      }
    }
    Fig: westack {
      tool(id: "fig-autocomplete") {
        ...ToolInfo
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tool = makeShortcode("Tool");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{props.pageContext.frontmatter.title}</h1>
    <Tool tool={props.data.Paddlehub.tool} mdxType="Tool" />
    <Tool tool={props.data.Bubbletea.tool} mdxType="Tool" />
    <Tool tool={props.data.WXInlinePlayer.tool} mdxType="Tool" />
    <Tool tool={props.data.Hyperfine.tool} mdxType="Tool" />
    <Tool tool={props.data.NoSleep.tool} mdxType="Tool" />
    <Tool tool={props.data.Turndown.tool} mdxType="Tool" />
    <Tool tool={props.data.TransferSH.tool} mdxType="Tool" />
    <Tool tool={props.data.QuestDB.tool} mdxType="Tool" />
    <Tool tool={props.data.Pattern.tool} mdxType="Tool" />
    <Tool tool={props.data.Fig.tool} mdxType="Tool" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      